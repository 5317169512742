import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import StyledLink from "../../../DesignSystem/StyledLink";
import { Container } from "../../../styles/UI";

import * as S from "./style";

export default function SobreHome() {
  return (
    <S.Sobre>
      <Container>
        <div className="textblock">
          <h4>Somos uma empresa de tecnologia tributária</h4>
          <p>
            Somos uma empresa de tecnologia especializada em criar soluções para
            os profissionais da área tributária. Sempre movidos pelo pioneirismo
            em unir conhecimentos em tecnologia e tributos, temos como propósito
            simplificar a vida dos profissionais da área fiscal por meio de
            plataformas plug and play que agilizam a gestão da carga tributária
            e de compliance fiscal nas empresas.
          </p>
          <StyledLink to="/sobre" arrow>
            Saiba mais sobre a Taxcel
          </StyledLink>
        </div>
        <StaticImage
          src="../../../images/sobre.png"
          alt="foto de homem sorrindo com o logo da Taxcel ao fundo"
          quality={100}
          className="img-sobre-wrapper"
          imgClassName="img-sobre"
        />
      </Container>
    </S.Sobre>
  );
}
