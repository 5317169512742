import styled from "styled-components";
import { Container } from "../../../styles/UI";

export const Sobre = styled.section`
position: relative;
background-color: var(--color-white);
text-align: center;

.textblock {
  text-align: left;
  padding-top: 64px;

  h4 {
    font-weight: 600;
    font-size: 29px;
    line-height: 42px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
  }

  p {
    font-weight: 350;
    font-size: 18px;
    line-height: 32px;
    color: ${(p) => p.theme.textLightGray};
    margin: 32px 0;
  }
}

@media (min-width: ${(p) => p.theme.bp.tablet}) {
  ${Container} {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
  }

  .textblock {
    padding: 148px 0;
    width: 50%;
    margin-left: 32px;
  }

  .img-sobre-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
  }
}
`;