import React from "react";

import * as S from "./style";

export default function Hero({ children, clip }) {
  return (
    <S.Hero $clip={clip}>
      <S.Container>{children}</S.Container>
    </S.Hero>
  );
}
