import styled from "styled-components";

export const Bubble = styled.div`
  background-color: ${(p) => p.theme.primary};
  border-radius: 64px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
      content: "";
      display: block;
      height: 14px;
      width: 14px;
      border-radius: 14px;
      background-color: ${p => p.$color ? p.theme[p.$color] : "var(--color-white)"};
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateX(50%) translateY(-50%);
  }

  span {
    color: var(--color-white);
    font-weight: 600;
    font-size: 21px;
  }
`;
