import styled from "styled-components";
import { Container } from "../../../styles/UI";

export const Clientes = styled.section`
background: ${(p) => p.theme.branco};
position: relative;

.grid-1 {
  padding: 64px 0 32px;
}

p {
  font-size: 21px;
  line-height: 42px;
  letter-spacing: -0.01em;
  color: ${(p) => p.theme.textLightGray};
}

h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: ${(p) => p.theme.primary};
}

.img-couple-wrapper {
  display: block;
  width: calc(100vw + 64px);
  margin-left: -32px;

  .img-couple {
    object-position: -50px 50%;
  }
}

.paralax2 {
  display: none;
}

@media (min-width: ${(p) => p.theme.bp.tablet}) {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    background: ${(p) => p.theme.laranja};
    height: 8px;
    border-radius: 0px 0px 0px 100px;
    bottom: -8px;
    right: 0;
    width: 45%;
  }

  & ${Container} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  h5 {
    font-size: 24px;
    line-height: 36px;
    max-width: 420px;
  }

  .grid-1 {
    padding: 64px 0;
    position: relative;

    .paralax2 {
      position: absolute;
      display: block;
      left: -258px;
      top: -80px;
    }
  }

  .img-couple-wrapper {
    display: block;
    position: absolute;
    height: 100%;
    width: 45%;
    right: 0;
    margin-left: 0;

    .img-couple {
      object-position: 50% 50%;
    }
  }

  .client-logos {
    max-width: 500px;
    margin-left: -8px;
  }
}
`;
