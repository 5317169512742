import React from 'react'

import * as S from "./style"

export default function Bubble({content, color}) {
    return (
        <S.Bubble $color={color}>
            <span>{content}</span>
        </S.Bubble>
    )
}
