import React, { useLayoutEffect, useRef, useState } from "react";
import { Container } from "../../../styles/UI";
// import CarouselButton from "../../../components/CarouselButton";

import paralax from "../../../images/paralax.svg";

import { useViewportScroll, motion, useTransform } from "framer-motion";

import * as S from "./style";
import TestimonialsWrapper from "../../../components/Testimonials";

export default function DepoimentosHome() {
  const ref = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  useLayoutEffect(() => {
    if (!ref.current) return null;
    let bodyRect = document.body.getBoundingClientRect();
    let elementRect = ref.current.getBoundingClientRect();
    console.log(elementRect.top - bodyRect.top);
    setOffsetTop(elementRect.top - bodyRect.top);
  }, [ref]);

  const { scrollY } = useViewportScroll();
  const y1 = useTransform(
    scrollY,
    [offsetTop - 600, offsetTop + 600],
    ["0%", "100%"]
  );

  return (
    <S.Depoimentos>
      <Container>
        <motion.img
          ref={ref}
          src={paralax}
          style={{ y: y1 }}
          className="paralax1"
          alt=""
        />
        <h4>
          Desde 2014, <br />
          simplificamos o dia a dia de quem trabalha com tributos no Brasil
        </h4>
        <TestimonialsWrapper className="cards" />
        {/* <div className="buttons">
          <CarouselButton />
          <CarouselButton />
        </div> */}
      </Container>
    </S.Depoimentos>
  );
}
