import React from 'react'

import * as S from "./style"

export default function HeroCard({children, mt}) {
    return (
        <S.HeroCard $mt={mt}>
            {children}
        </S.HeroCard>
    )
}
